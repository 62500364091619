/*----- mediaqueries -----------------------------------------*/
@media all and (min-width:1700px) {
/*********************************************************************
	HEADER
*********************************************************************/		
	.header__content__left_box {
		width: 50%;
		padding-left: 20%;
	}
	.header__content__right_box {
		margin-left: 10%;
		
		img {
			bottom: inherit;
			top: 0px;
		}
	}
} //end min-width:1700

@media all and (max-width:1200px) {
/*********************************************************************
	GLOBAL RESET
*********************************************************************/	
	h1 {
		font-size: @fontSize100;	
		margin-bottom: 10%;
		margin-top: 4%;
	}
	
	h2 {
		font-size: @fontSize24;
		margin-bottom: 10%;
	}
	
	h3 {
		font-size: @fontSize36;
	}
	
	h5 {
		font-size: @fontSize18;
	}
	
	.section-article ul li {
		padding-left: 1.7em;
		text-indent: -1.4em;
	}
	
	.section-article {
		p {
			font-size: @fontSize24;	
		}
		
		.options {
			font-size: @fontSize16;	
			
			&.options__addition {
				font-size: @fontSize20;	
			}
		}
		
		.price__price {
			span {
				font-size: @fontSize30;
			}
			p {
				width: 75%;
				font-size: @fontSize55;
			}
		}
		
		.grid__8 {
			p {
				font-size: @fontSize18;
			}
		}
	}
	
	ul {
		font-size: @fontSize18;
	}
	
	#functions {
		.section-article {
			p {
				font-size: @fontSize18;
			}
		}
	}
	
	.grid__12 {
		max-width: 936px;
	}
	
	.grid__8 {
		max-width: 624px;
	}
	
	.grid__4 {
		max-width: 314px;
	}
	
/*********************************************************************
	NAVIGATION/HEADER
*********************************************************************/
	.nav-main {
		ul {
			font-size: @fontSize24;
		}
	}
	
	.header__content__left_box {
		width: 50%;
		padding-left: 4%;
		padding-bottom: 26%;
		padding-top: 0;
	}
	
	.header__content__right_box {
		width: 50%;
		
		img {
			left: 20px;
		}
	}

/*********************************************************************
	VIDEO
*********************************************************************/	
	.content__video {
		margin-bottom: 30px;
	}
	
	.content__video-shadow {
		.play__button {
			height: 80%;
			background-size: 83px;
		}
		
		.video__wrapperfull__width {
			height: 555px;
		}
	}

/*********************************************************************
	STEPS
*********************************************************************/	
	.article__steps-wrapper {
		text-align: center;
	}
	
	.article__steps {
		max-width: 291px;
		p {
			text-align: left;
		}
	}

/*********************************************************************
	PRICING
*********************************************************************/	
	.article__price {
		max-width: 292px;
		
		h3 {
			font-size: @fontSize30;
		}
		
		ul {
			font-size: @fontSize16;
		}
		
		span {
			font-size: @fontSize20;
		}
	}
	
/*********************************************************************
	SWIPER
*********************************************************************/	
	.swiper-slide {
		padding-left: 80px;
		padding-right: 80px;
	}
	
	.footer__content {
		&.grid__8 {
			max-width: 780px;
		}
	}
	
	.logo-wrapper {
		img {
			max-height: 32px;
			padding-right: 30px;
		}
	}
} //end 1200

@media all and (max-width:1100px) {
/*********************************************************************
	NAVIGATION/HEADER
*********************************************************************/	
	.navigation__mobile {
		display: inline-block;
		font-size: @fontSize18;
		text-transform: uppercase;
		text-align: center;
		vertical-align: top;
		margin-top: 28px;
		cursor: pointer;
	
		p {
			width: 100%;
			text-align: center;
		}
		
		.mobile__close {
			display: none;
			margin-top: 2px;
			
			&.open {
				display: block;
			}
		}
		
		.mobile__menu {

			&.hide {
				display: none;
			}
		}
	}
	
	.navigation {
		width: 100%;
		height: 0;
		margin: 0;
		position: absolute;
		overflow: hidden;
		left: 0;
		top: 80px;
		background-image: url('/site/images/shadow_header.png');
		background-position: center -13px;
		background-repeat: repeat-x;
		background-color: @collaxoWhite;
		.animationSetup(0,0,0);
		.animationSpeed(all,0.1s);
		
		&.open {
			height: 395px;
			.animationSetup(0,0,0);
			.animationSpeed(all,0.4s);
		}
		
		li {
			width: 100%;
			padding-right: 0;
			padding-bottom: 17px;
			
			line-height: 1;
			
			&:first-child{
				padding-top: 40px;
			}
		}
	}
	
/*********************************************************************
	CONTENT
*********************************************************************/		
	#page {
		margin-bottom: 525px;
	}
	
	.section-article ul li {
		padding-left: 1.7em;
		text-indent: -1.5em;
	}
	
/*********************************************************************
	VIDEO
*********************************************************************/		
	.content__video {
		.video-js {
			.vjs-control-bar {
				display: inherit;
			}
		}
	}
	
/*********************************************************************
	FOOTER
*********************************************************************/			
	.footer__links {
		a {
			margin-right: 0;
			padding-right: 50px;
			
			&:nth-child(2) {
				padding-right: 0;	
			}
		}
	}
	
	#page__footer {
		height: 525px;
	}
	
	.footer__inner-wrapper {
		padding-top: 160px;
	}
	
	.mfp-content {
		padding-top: 30px;
	}
} //end 1100

@media all and ( max-width: 999px ) {
/*********************************************************************
	GLOBAL RESET
*********************************************************************/	
	h1 {
		width: 60%;
		font-size: @fontSize90;
		margin-bottom: 5%;
	}
	
	h2 {
		font-size: @fontSize18;
		margin-bottom: 5%;
	}
	
	.button__cta {
		width: 150px;
		height: 46px;
		line-height: 42px;
		font-size: @fontSize14;
		
		.button_arrow {
			background-position: 31px 15px;
		}
		
		.button__stroke {
			right: 40px;
		}
		
		&:hover {
			.button_arrow {
				background-position: 39px 15px;				
			}
		}
	}
	
	.grid__12 {
		max-width: 780px;
	}
	
		
	.grid__4 {
		max-width: 260px;
	}
	
	.grid__8 {
		max-width: 520px;
	}
	
	
	.video-js .vjs-fullscreen-control {
		display: none;
	}
	
/*********************************************************************
	NAVIGATION/HEADER
*********************************************************************/	
	.header__content-wrapper {
		.button__cta {
			padding-left: 20px;
		}
	}

		.article__steps {
			&:nth-child(2){
				margin-right: 0;
			}	
		}
/*********************************************************************
	PRICING
*********************************************************************/		
	.article__pricing-wrapper {
		background-image: none !important;
	}
	
	.pricing__content-wrapper {
		width: 614px;
		padding: 0 0 50px 0;
		margin: 0 auto;
		text-align: right;
	}
	
	.article__price {
		margin-top: 0;
		margin-bottom: 25px;
		
		h3 {
			padding-top: 35px;
			padding-bottom: 25px;	
		}
		
		ul {
			min-height: 200px;
		}
		
		&:nth-child(2) {
			margin-right: 0;
		}
	}
	
	.price__inner-wrapper {
		min-height: 455px;
	}
	
	.logo-wrapper {
		img {
			max-height: 22px;
			padding-right: 20px;	
		}
	}
	
	.content__video-shadow {
		.video__wrapperfull__width {
			height: 468px;
		}
	}

} //end 999

@media all and ( max-width: 800px ) {
/*********************************************************************
	NAVIGATION/HEADER
*********************************************************************/		
	.header__content-wrapper {
		padding-top: 60px;
	}
	
	.logo {
		margin-top: 18px;
		width: 130px;
	}

	.container {
		height: 60px;
	}
	
	.navigation {
		top: 60px;
	}

	.navigation__mobile {
		margin-top: 18px;
	}
	
	.navigation__language {
		margin-top: 15px;
		margin-right: 0;
	}
	
	.navigation__meta-info {
		.button__invert {
			display: none;
		}
	}
	
	.nav-main  {
		top: 60px;
	}
	

	.header__content__left_box {
		padding-bottom: 0;
	}
	
	.header__content__right_box {
		width: 100%;
		min-height: 640px;
		margin-top: -25%;
		
		img {
			bottom: inherit;
			top: 0;
			left: 20%;
		}
	}
	
	.button__cta {
		margin-bottom: 20px;
	}
	
/*********************************************************************
	GRID
*********************************************************************/		
	.content__section {
		padding-top: 95px;
	}
	
	.grid__12 {
		max-width: 100%;
		//padding-left: 30px;
		//padding-right: 30px;
	}
	
	.grid__4 {
		max-width: 540px;
		display: block;
	}
	
	.grid__8 {
		max-width: 100%;
		
		h3 {
			width: 80%;
		}
	}
	
	.section-article {
		.grid__4 {
			padding-left: 78px;
			padding-bottom: 100px;
		}
		
		.grid__8 {
			padding-left: 78px;
			padding-right: 80px;
		}
	}
	
	#context {
		.content__section {
			padding-top: 50px;
		}
	}
	
/*********************************************************************
	VIDEO
*********************************************************************/		
	.content__video-shadow {
		padding-top: 70px;
		
		 .video__wrapperfull__width {
			height: 442px;
		 }
	}
	
	.content__video {
		max-width: 520px;	
		margin-top: 30px;
		margin-bottom: 45px;
		
		.video__wrapperfull__width {
			height: auto;
		}
		
		.video-js {
			height: 520px;
		}
	}
	
	#functions {
		.section-article {
			p {
				padding-bottom: 40px;
			}
		}
	}
	
	#advantage {
		.section-article {
			.grid__4 {
				padding-bottom: 0;
			}
		}
	}
	
/*********************************************************************
	SWIPER/REFERENCES
*********************************************************************/		
	.swiper-slide {
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 80px;
		p {
			font-size: @fontSize18;
		}
	}
	
	.swiper-slide__name {
		p {
			font-size: @fontSize14;
		}
	}
	
	#references {
		padding-top: 75px;
	}
	
	.swiper-button-next, 
	.swiper-button-prev {
		top: 50%;
	}
	
	.swiper-button-next {
		right: 30px;
	}
	
	.swiper-button-prev {
		left: 30px;
	}
			
	.article__references {
		padding-left: 30px;
		padding-right: 30px;
		padding-bottom: 72px;
		
		p {
			font-size: @fontSize14;
		}
	}
	
	.logo-wrapper {
		padding-left: 0;
		padding-right: 0;
		
		img {
			max-height: 19px;
			padding-right: 25px;
		}
	}
} // end 800

@media all and ( max-width: 750px ) {
	.footer__links	{
		a {
			display: block;
			padding-right: 0;
		}
	}
	
	.logo-wrapper {
		padding-bottom: 0;

		img {
			max-height: 33px;
			padding-right: 35px;
			margin-bottom: 39px;
		}
	}
	
	.content__video-shadow {
		padding-top: 30px;
		
		.video__wrapperfull__width {
			width: 600px;
			height: 367px;
			margin-left: auto;
			margin-right: auto;
		}
	}
} //end 750

@media all and ( max-width: 650px ) {
/*********************************************************************
	RESETS
*********************************************************************/	
	h1 {
		font-size: @fontSize55; 
		margin-top: 8%;
	}
	
	h2 {
		margin-bottom: 150px;
	}
	
	h4 {
		padding-bottom: 25px;
	}
	
	h3 {
		font-size: @fontSize24;
		text-align: left;
	}
	
	.button__cta {
		width: 130px;
		height: 40px;
		line-height: 36px;
		font-size: @fontSize11;
		display: block;
		
		.button_arrow {
			background-position: 31px 13px;
			background-size: 22% 22%;
		}
	}
	
	#page {
		margin-bottom: 450px;
	}
	
	.grid__12 {
		padding-left: 20px;
		padding-right: 20px;	
	}
	
	.grid__4 {
		max-width: none;
	}
	
	#context {
		.grid__4 {
			max-width: 215px;
		}
	}
	
/*********************************************************************
	NAVIGATION/HEADER
*********************************************************************/		
	.logo {
		left: 20px;
	}
	
	.navigation__mobile {
		margin-left: 27%;
	}
	
	.navigation__meta-info {
		right: 20px;
	}
	
	.header__content__left_box {
		width: 60%;
	}
	
	.header__content__right_box {
		min-height: 410px;
		margin-top: -75%;
		
		img {
			left: 35%;
		}
	}
	
/*********************************************************************
	GRID
*********************************************************************/		
	.content__section {
		padding-top: 45px;
	}
	
	.section-article {
		.price__price {
			p {
				text-align: right;
			}
		}
		
		p {
			text-align: left;
			font-size: @fontSize18;
		}
		
		.grid__4 {
			padding-left: 20px;
			padding-bottom: 40px;
		}
		
		.grid__8 {
			padding-left: 20px;
			padding-right: 20px;
		}
	}
	
/*********************************************************************
	VIDEO
*********************************************************************/		
	.content__video-shadow {
		//padding: 60px 0 0;
		
		.play__button {
			background-size: 54px;
			background-image: none;
		}
		
		.video__wrapperfull__width {
			width: 370px;
			height: 224px;
			padding-bottom: 16px;
		}
	}
	
	.content__video {
		max-width: 480px;
		
		.video-js {
			height: 480px;
		}
	}
	
/*********************************************************************
	STEPS
*********************************************************************/		
	.article__steps {
		max-width: 100%;
		padding: 0 20px;
		margin-right: 0;
	}

	.article__steps-wrapper {
		 &.grid__12 {
		 	padding-bottom: 84px;			 
		 }
	}
	
/*********************************************************************
	PRICING
*********************************************************************/		
	.article__pricing-wrapper {
		width: 292px;
		margin: 0 auto;
	}
	
	.pricing__content-wrapper {
		padding: 0 0 17px 0;
		width: auto;
	}
	
	.article__price {
		max-width: none;
		
		&:nth-child(2) {
			margin: 0 0 25px 0;
		}
	}
	
	#advantage {
		padding-bottom: 110px;
	}
	
	#references {
		padding-top: 90px;
	}
	
/*********************************************************************
	SWIPER/REFERENCES
*********************************************************************/		
	.swiper-button-next,
	.swiper-button-prev {
		top: 5%;
	}
	
	.swiper-button-next {
		right: 30%;
	}
	
	.swiper-button-prev {
		left: 30%;
	}
	
	.swiper-wrapper {
		padding-top: 90px;
	}
	
	.article__references {
		p {
			width: 60%;
			margin: 0 auto;
			font-size: @fontSize14;
			text-align: center;			
		}
	}
	
	.logo-wrapper {
		img {
			max-height: 35px;
			display: block;
			margin: 0 auto 39px;
		}
	}
	
/*********************************************************************
	FOOTER
*********************************************************************/		
	#page__footer {
		height: 455px;
	}
	
	.footer__inner-wrapper {
		padding-top: 100px;
	}
	
	.footer__content {
		position: relative;
		height: 455px;
		.clearfix();
	}
	
	.footer__links {
		width: auto;
		text-align: left;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		
		a {
			width: 100%;
			font-size: @fontSize24;
			padding-right: 0;
			padding-bottom: 40px;
		}
	}
	
	.icon__arrow {
		width: 20px;
		height: 21px;
		background-size: contain;
	}
	
	.footer__impressum {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		top: 270px;
		
		p {
			display: block;
		}
	}
	
	.impressum {
		.section-article {
			p {
				text-align: left;
				padding-bottom: 50px;
			}
		}
	}
	
	.xform {
		padding-left: 20px;
		padding-right: 20px;
	}
} // end 650

@media all and ( max-width: 450px ) {
	.footer__links {
		width: 60%;
		left: 55%;
		transform: translateX(-55%);
	}
} // end 450

@media all and ( max-width: 400px ) {
	.header__content__right_box {
		min-height: 350px;
		margin-top: -95%;
	}
	
	.footer__links {
		width: 71%;
	}
	.content__video {
		max-width: 320px;	
		
		.video-js {
			height: 320px;
		}
	}
	
	.content__video-shadow {
		padding-top: 30px;
		
		.video__wrapperfull__width {
			width: 320px;
			height: 194px;
		}
	}
} // end 380

@media all and ( max-width: 350px ) {
	
	.logo {
		left: 15px;
		
		svg {
			width: 120px;
		}
	}
	
	.navigation__meta-info {
		right: 15px;
	}
	
	.logo-wrapper {
		img {
			padding-right: 0;
			width: auto;
			margin-left: auto;
			margin-right: auto;
			display: block;
		}
	}

	.footer__impressum {
		top: 220px;
	}
	
	.footer__inner-wrapper {
		padding-top: 150px;
	}	
	
	.footer__links {
		padding-bottom: 0;
	}
	
	.footer__form {
		p {
			font-size: @fontSize16;
		}
	}
	
	.radios {
		>.radio {
			&:first-child {
				padding-right: 12px;
			}
		}
	}
	
	.content__video-shadow {
		padding-top: 30px;
		
		.video__wrapperfull__width {
			width: 280px;
			height: 171px;
			padding-bottom: 13px;
		}
	}

} //end 350
