
* {
	margin: 0;
	padding: 0;
}
* {
	.box-sizing();
}
html, html a {
	-webkit-font-smoothing: antialiased !important;
	-moz-osx-font-smoothing: grayscale !important;
}
html {
	font-size: 62.5%;
	hyphens: none;
	height:auto;
}
body {
	font-family: @vaud;
	letter-spacing: 0;
	font-weight: normal;
	font-size: 1em;
	line-height: @body-line-height;
	height:auto;
	background: white;
}

/*********************************************************************
	DEFAULT TYPOGRAPHY
*********************************************************************/
div, li, p, a, strong, span,
b, u, i,
blockquote,
pre, code,
small {
	line-height: @body-line-height;
	padding: 0px;
}
select {
	border-radius: 0;
}
h1
h2,
h3,
fieldset,
fieldset fieldset,
ol, ul, li,
p,
a,
input,
textarea,
header,
footer,
aside,
article {
	text-decoration: none;
	margin: 0px;
	padding: 0px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: normal;
}
address {
	font-style: normal;
}
fieldset,
fieldset fieldset,
form {
	border:none;
}
ol,
ul {
	list-style: none;
	width: 100%;
	font-size: @fontSize24;
	font-family: @vaudMedium;
}
a {
	text-decoration: none;
	font-family: @vaud;
	color: @collaxoBlack;
	display: inline-block;
	
	&:focus {
		outline: none;
	}
	
	&:hover,
	&.active {
		text-decoration: underline;
	}
}

p {
		width: 100%;
		font-size: 1.6rem;
		display: inline-block;
		text-align: left;
}

div,
article {
	display: block;
	border: none;
}

img {
	border: none;
	max-width: 100%;
}

/*********************************************************************
	HEADINGS
*********************************************************************/
h1,
h3,
h4 {
	font-family: @vaudBold;	
}

h3,
h5 {
	color: @collaxoTurquoise;
}

h1 {
	margin: 3.5% 0% 4% 0%;
	font-size: @fontSize144;
	line-height: 1;
	letter-spacing: 2px;
	color: @collaxoWhite;
}

h2 {
	margin: 0% 0% 4% 0%;
	font-size: @fontSize32;
	color: @collaxoWhite;
}

h3 {
	font-size: @fontSize48;
	line-height: 1.1;
	margin-bottom: 25px;
}

h4 {
	font-size: @fontSize48;
	text-align: left;
	padding: 0 0 35px 0;
	margin: 0 0 40px 0;
	border-bottom: @borderTurquoise;
	
	span {
		line-height: 0.85;
	}
	
	.title__one {
		width: 100%;
		display: block;
	}
	
	.title__separator {
		color: @collaxoTurquoise;
	}
}

h5 {
	font-size: @fontSize24;
	margin: 40px 0 0 0;
	padding: 0 0 15px 0;
}

/*********************************************************************
	GRID
*********************************************************************/
.grid_fullwidth {
	max-width: 100%;
	width: 100%;
}

.grid__12 {
	max-width: 1140px;
	width: 100%;
	margin: 0 auto;
	padding: 0 98px;
}

.grid__4 {
	max-width: 380px;
	width: 100%;
	display: inline-block;	
	vertical-align: top;
}

.grid__8 {
	max-width: 760px;
	width: 100%;
	display: inline-block;		
}

/*********************************************************************
	GRADIENTS
*********************************************************************/
.gradient {
	background: -moz-linear-gradient(270deg, rgba(66,219,205,1) 0%, rgba(45,183,174,1) 100%); /* ff3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(66,219,205,1)), color-stop(100%, rgba(45,183,174,1))); /* safari4+,chrome */
	background: -webkit-linear-gradient(270deg, rgba(66,219,205,1) 0%, rgba(45,183,174,1) 100%); /* safari5.1+,chrome10+ */
	background: -o-linear-gradient(270deg, rgba(66,219,205,1) 0%, rgba(45,183,174,1) 100%); /* opera 11.10+ */
	background: -ms-linear-gradient(270deg, rgba(66,219,205,1) 0%, rgba(45,183,174,1) 100%); /* ie10+ */
	background: linear-gradient(180deg, rgba(66,219,205,1) 0%, rgba(45,183,174,1) 100%); /* w3c */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#42dbcd', endColorstr='#2db7ae',GradientType=0 ); /* ie6-9 */	
}	

/*********************************************************************
	BUTTONS
*********************************************************************/
.button__cta {
	width: 195px;
	height: 59px;
	padding: 0 0 0 30px;
	display: inline-block;
	position: relative;
	border-radius: 100px;
	line-height: 34px;
	color: @collaxoWhite;
		
		
	&:hover {
		text-decoration: none;
		background-color: white;
	}
	
	border: 2px solid @collaxoWhite;
	
	font-size: @fontSize18;
	line-height: 55px;
	color: @collaxoWhite;
	text-align: left;
	
		.button__stroke {
			width: 2px;
			height: 100%;
			background-color: @collaxoWhite;
			opacity: 0.2;
			display: inline-block;
			
			position: absolute;
			right: 55px;
		}
		
		.button_arrow {
			.animationSetup(0,0,0);
			.animationSpeed(all,0.2s);
			background-image: url("/site/images/icon_arrow_button.svg");
			background-repeat: no-repeat;
			background-position: 26px 20px;
			width: 59px;
			height: 42px;
			display: inline-block;
			position: absolute;
			right: 0;
		}
		
	&:hover {
		text-decoration: none;
		background-color: @collaxoWhite;
		color: @collaxoTurquoise;
		
		
		.button_arrow {
			background-image: url("/site/images/icon_arrow_button_hover.svg");	
			background-position: 36px 20px;
		}
		
		.button__stroke {
			background-color: @collaxoTurquoise;
		}
	}
}


.button__invert {
	width: 151px;
	height: 32px;
	line-height: 28px;
	padding: 0;
	font-size: @fontSize16;
	border: 2px solid @collaxoTurquoise;
	text-align: center;
	color: @collaxoTurquoise;
	vertical-align: middle;
	
	&:hover {
		background-color: @collaxoTurquoise;
		color: @collaxoWhite;	
	}
}

.button__pricing {
	width: 100%;
	height: 60px;
	padding: 0 0 0 40px;
	background-color: @collaxoTurquoiseAlpha70;
	display: block;
	font-family: @vaudMedium;
	font-size: @fontSize24;
	color: @collaxoWhite;
	line-height: 60px;
	.animationSetup(0,0,0);
	.animationSpeed(all,0.2s);
}

/*********************************************************************
	NAVIGATION/HEADER
*********************************************************************/
.container {
	height: 80px;
	width: 100%;
	position: fixed;
	background-color: @collaxoWhite;
	box-shadow: 0 2px 4px 0px rgba(127,127,127,1);
	-webkit-box-shadow: 0 2px 4px 0px rgba(127,127,127,1);
	-moz-box-shadow: 0 2px 4px 0px rgba(127,127,127,1);
	z-index: 10;
	text-align: center;
	
	
	.container__wrapper {
		width: 100%;
		position: relative;
		display: inline-block;
	}
}

.logo {
	left: 30px;
	position: absolute;
	margin-top: 26px;
	
	svg {
		max-width: 100%;
	}
	
	#logo-collaxo {
		#arrow {
			.animationSetup(-50px,0,0);
			.animationSpeed(all,0.8s);
		}
	}
	
	&.animate {
		#logo-collaxo {
			#arrow {
				.animationSetup(0,0,0);
				.animationSpeed(all,0.8s);
			}
		}	
	}
	
	a {
		max-width: 179px;
		width: 100%;
		display: block;
	}
}

.navigation__mobile {
	display: none;
}

.navigation {
	display: inline-block;
	
}

.nav-main {
	width: 100%;
	padding-top: 20px;
	vertical-align: top;
	
	li {
		list-style: none;
		margin-right: 25px;
		display: inline-block;
		
		&:last-child {
			padding-right: 0;
		}

	}
	
	a {
		font-size: @fontSize18;
	}
}

.navigation__meta-info {
	display: inline-block;
	right: 30px;
	position: absolute;
}

//Language Switcher
.navigation__language {
	width: 30px;
	height: 30px;
	border-radius: 100px;
	display: inline-block;
	border: 2px solid @collaxoTurquoise;
	margin: 24px 20px 0px 0px;
	text-align: center;
	
	a {
		display: inline-block;
		width: 100%;
		height: 100%;
		line-height: 28px;
		color: @collaxoTurquoise;
		border-radius: 120px;

		&:focus,
		&:active {
			outline: none;
		}
	}
	
	&:hover {
		background-color: @collaxoTurquoise;
		a {
			text-decoration: none;
			color: @collaxoWhite;
		}
	}
}

/*********************************************************************
	MAINCONTENT / HEADER
*********************************************************************/
#page {
	margin-bottom: 454px;
	z-index: 5;
	position: relative;	

	background-color:@collaxoWhite;
}

#page__content {
	box-shadow: 0 2px 10px 0px rgba(0,0,0,0.8);
	-webkit-box-shadow: 0 2px 10px 0px rgba(0,0,0,0.8);
	-moz-box-shadow: 0 2px 10px 0px rgba(0,0,0,0.8);

	&.impressum {
		padding-top: 80px;	
	}
}

.header__content-wrapper {
	padding: 80px 0 0 0;
	
	.button__cta {
		margin-right: 35px;
	}
	
	img {
		width: 50%;
		display: inline-block;
	}
}

.header__content__left_box,
.header__content__right_box {
	display: inline-block;
	position: relative;
}

.header__content__left_box {
	width: 60%;
	padding: 0 0% 4% 10%;
	vertical-align: top;
	z-index: 6;
}

.header__content__right_box {
	width: 40%;
	min-height: 738px;
	vertical-align: bottom;
	position: relative;
	overflow: hidden;
	z-index: 5;
	
	img {
		width: 100%;
		position: absolute;
		bottom: 0;
	}
}

/*********************************************************************
	MAINCONTENT/section-article
*********************************************************************/
.content__section {
	padding: 150px 0 0 0;
}

#advantage {
	padding-bottom: 150px;
}


#advantage {
	ul {
		margin-top: 28px;
	}
}

.section-article {
	text-align: center;
	
	p {
		font-family: @vaudMedium;
		font-size: @fontSize32;
		line-height: 1.4;
		text-align: center;
	}
	
	.grid__4 {
		padding: 0 15px 0 0;
	}
	
	.grid__8 {
		text-align: left;
		padding: 0 0 0 15px;
		
		h3 {
			text-align: left;
		}
		
		p {
			font-size: @fontSize24;
			text-align: left;
			padding-bottom: 14px;
		}
	}
	
	ul {
		padding: 45px 0px;
		margin-bottom: 40px;
		border-bottom: @borderTurquoise;
		border-top: @borderTurquoise;
		
		&.article__list_without__title {
			margin-top: 50px;	
		}
		
		li { 
			padding-left: 1.7em;
			text-indent: -1.2em;
		}
		
		li:before {
			content: "•";
			color: @collaxoTurquoise;
			padding-right: 17px;
		}
	}
}

/*********************************************************************
	VIDEO
*********************************************************************/
.content__video-shadow {
	padding: 110px 0 0 0;
	position: relative;
	
	.play__button {
		background-image: url('/site/images/icon_play.svg');
		background-position: center center;
		background-repeat: no-repeat;
		background-size: 100px;
		position: absolute;
		width: 100%;
		height: 91%;
		z-index: 2;
		
		&.hide {
			background-image: none;
		}
	}
	
	.video__wrapperfull__width {
		width: 100%;
		height: 670px;
		border: none;
		background-image: url('/site/images/shadow_video.png');
		background-position: center bottom;
		padding-bottom: 30px;
		background-repeat: no-repeat;
		background-size: contain;
		position: relative;
		
		cursor: pointer;
	}
	
	.video-js {
		width: 100%;
		height: 100%;
		position: relative;
		z-index: 1;
	}
}

@-moz-document url-prefix() {
	.content__video-shadow {
		 .play__button {
			background-position: center 45%;
		 }
	}
}

.vjs-poster {
	background-color: @collaxoWhite;
}

video {
	background-color: @collaxoWhite;
}

/*********************************************************************
	STEPS
*********************************************************************/
.article__steps-wrapper {
	&.grid__12 {
		padding: 0;
	}
}

.article__steps {
	max-width: 262px;
	width: 100%;
	vertical-align: top;
	margin-right: 30px;
	display: inline-block;
	
	p {
		text-align: left;
	}
	
	&:last-child {
		margin-right: 0;
	}
	
	.steps__header {
		position: relative;
		min-height: 51px;
		margin: 0 0 20px 0;
		
		h5 {
			font-size: @fontSize18;
			text-align: left;
			padding-bottom: 10px;
			border-bottom: @borderTurquoise;
		}
	}
	
}

.icon__steps {
	width: 43px;
	height: 51px;
	position: absolute;
	top: 0;
	right: 10px;
}

/*********************************************************************
	PRICING
*********************************************************************/
.article__pricing-wrapper {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}

.pricing__content-wrapper {
	width: auto;
	text-align: center;
	margin: 0 30px;
}

.price__inner-wrapper {
	background-color: @collaxoBlackAlpha70;
	min-height: 555px;	
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	.animationSetup(0,0,0);
	.animationSpeed(all,0.4s);
}

.options-wrapper {
	.price__inner-wrapper {
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;	
	}	
}

.article__price {
	max-width: 359px;
	width: 100%;
	margin: 200px 0 110px 0;
	border-radius: 5px;
	border: 1px solid rgba(67, 221, 206, 0.5);
	display: inline-block;
	vertical-align: top;
	text-align: left;
	position: relative;
	.animationSetup(0,0,0);
	.animationSpeed(all,0.4s);
	
	.button__pricing {
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
	}
	
	&:hover {
		.animationSetup(0,0,0);
		.animationSpeed(all,0.4s);
		text-decoration: none;
		
		.price__inner-wrapper {
			background-color: @collaxoBlack;
			.animationSetup(0,0,0);
			.animationSpeed(all,0.4s);
		}
		
		.button__pricing {
			text-decoration: none;
			background-color: @collaxoTurquoise;
			.animationSetup(0,0,0);
			.animationSpeed(all,0.4s);
		}
	}
	
	&:nth-child(2) {
		margin-left: 30px;
		margin-right: 30px;
	}
	
	h3 {
		padding: 50px 25px 40px 25px;
		font-size: @fontSize36;
		margin-bottom: 0;
	}
	ul {
		padding: 0 25px;
		min-height: 260px;
		margin-bottom: 0;
		border: transparent;
		color: @collaxoWhite;
		font-size: @fontSize21;
		font-family: @vaud;
		
		li {
			padding-bottom: 15px;
			
			&:last-child {
				padding-bottom: 0;
			}
			
			&:before {
				color: @collaxoWhite;
				content: '-';
			}
		}
	}
}

.price__price {
	padding: 0 25px;
	color: @collaxoTurquoise;
	
	span {
		font-size: @fontSize36;
	}
	
	p {
		width: 77%;
		line-height: 1;
		display: inline-block;
		font-family: @vaudBold;
		font-size: @fontSize72;
		text-align: right;
	}
}

.section-article {
	
	.options {
		padding: 0 25px;
		font-size: @fontSize20;
		color: @collaxoWhite;
		opacity: 0.7;
		text-align: left;
		
		&.options__addition {
			font-size: @fontSize24;
			padding-top: 32px;
			opacity: 1;
			color: @collaxoTurquoise;
			line-height: 1.1;
		}
	}
}

#functions {
	.section-article {
		p {
			font-size: @fontSize24;
			padding-bottom: 28px;
		}
		
		&.article__steps {
			p {
				padding-bottom: 20px;
			}
		}
	}
	
	.article__steps {
		p {
			font-size: @fontSize16;
		}
	}
}

#functions,
#offer {
	padding-top: 0;
}

/*********************************************************************
	SWIPER/REFERENCES
*********************************************************************/

.swiper-container {
	margin: 0 auto;
	display: block;
	padding: 0;
}
.swiper-slide {
	color: @collaxoWhite;
	padding: 0 145px 90px 145px;
	
	p {
		font-family: @vaudSemiBold;
		font-size: @fontSize24;
		text-align: center;
	}
}

.swiper-button-prev,
.swiper-button-next {
	width: 12px;
	height: 21px;
	background-size: contain;
	top:55%;
}

.swiper-button-prev {
	background-image: url('/site/images/icon_arrow_prev.svg');
}

.swiper-button-next {
	background-image: url('/site/images/icon_arrow_next.svg');
}
.swiper-slide__name {
	p {
		margin: 50px 0 0 0;
		font-family: @vaudBold;	
		font-size: @fontSize14;
		letter-spacing: 2px;
		text-transform: uppercase;
	}
	
	span {
		font-family: @vaud;
		font-size: @fontSize14;
		text-align: center;
		display: block;
		opacity: 0.8;
	}
}

.article__references {
	padding-bottom: 135px;
	margin-bottom: 0;
	
	p {
		max-width: 480px;
		width: 100%;
		padding: 0 0 30px 0;
		font-size: @fontSize18;
		color: @collaxoWhite;
		text-align: center;
	}
}

.logo-wrapper {
	border-bottom: 1px solid @collaxoWhite;
	border-top: 1px solid @collaxoWhite;
	padding: 35px 0;
	
	img {
		max-height: 44px;
		padding-right: 46px;
		vertical-align: middle;
		
		&:last-child {
			padding-right: 0;
		}
	}
}

/*********************************************************************
	FOOTER
*********************************************************************/
#page__footer {
	background-color: @collaxoGrey;
	height: 464px;

	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
}

.footer__inner-wrapper {
	width: 100%;
	display: block;
	margin: 0 auto;
	padding: 115px 0 0 0;

	text-align: center;
	position: absolute;
	z-index: 1;
	opacity: 1;

}

.footer__links {
	padding: 0 0 60px 0;
	a {
		font-family: @vaudBold;
		font-size: @fontSize36;
		color: @collaxoTurquoise;
		padding: 0 0 30px 0;
		margin-right: 50px;
		display: inline-block;
		
		&:hover {
			.icon__arrow {
				.animationSetup(-10px,0,0);
				.animationSpeed(all,0.2s);
			}
		}
	}	
}

.footer__impressum {
	p {
		width: auto;
		font-size: @fontSize18;
		color: @collaxoWhite;
		text-align: center;
		display: inline-block;
	}
	a {
		font-size: @fontSize18;
		color: @collaxoTurquoise;	
	}
}
.icon__arrow {
	width: 33px;
	height: 33px;
	background-image: url('/site/images/icon_arrow_footer.svg');
	background-repeat: no-repeat;
	display: inline-block;
	margin: 0 10px -4px 0;
	.animationSetup(0,0,0);
	.animationSpeed(all,0.2s);
}


/*********************************************************************
	VIDEO JS
*********************************************************************/
.content__video {
	max-width: 815px;
	margin: 0 auto;
	
	.video-js {
		max-width: 100%;
		width: 100%;
		height: 815px;
		background-color: white;
		
		.vjs-control-bar,
		.vjs-big-play-button {
			display: none;
		}	
		
		.vjs-slider {
			display: none;
		}

	}
}

.content__video-shadow {
	.video-js {

	}
}


.video-js.vjs-default-skin .vjs-big-play-button { display: none; }


/*********************************************************************
	FORM IN OVERLAY
*********************************************************************/
.mfp-bg {
	background-color: @collaxoGrey;
	opacity: 1;
}

.mfp-content {
	vertical-align: top;
	padding: 95px 0 0 0;
}
.mfp-close-btn-in .mfp-close {
	width: 100%;
	font-family: @vaudMedium;
	font-size: @fontSize18;
	color: @collaxoTurquoise;
	opacity: 1;
	cursor: pointer;
	padding-left: 0;
	
	&:hover {
		text-decoration: underline;
	}
}

.footer__form {
	max-width: 360px;
	padding: 80px 0 0 0;
	display: block;
	margin: 0 auto;
	position: relative;
	
	p {
		color: @collaxoWhite;
		font-size: @fontSize18;
	}
}

.error__message {
	.form_warning {
		font-size: @fontSize18;
		color: red;
		padding-bottom: 20px;
	}
}

.rex-message {
	padding-top: 150px;
	.rex-info {
		p {
			font-size: @fontSize24;
			color: @collaxoTurquoise;
			text-align: center;
		}
	}
}

.radios  {
	padding: 25px 0 30px;
	
	>.radio {
		width: auto;
		line-height: 1;
		height: 25px;
		display: inline-block;
		
		&:first-child {
			padding-right: 30px;
		}
	}
	
	label {
		padding: 0 0 0 20px;
		vertical-align: super;
	}
}

::-webkit-input-placeholder { /* WebKit, Blink, Edge */
	color:@collaxoWhiteAlpha50;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
	color: @collaxoWhiteAlpha50;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
	color: @collaxoWhiteAlpha50;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
	color:	@collaxoWhiteAlpha50;
}

.wrapper-top {
	padding-bottom: 40px;
}

input {
	width: 100%;
	height: 60px;
	margin: 0 0 20px 0;
	padding: 0 0 0 20px;
	background-color: @collaxoWhiteAlpha05;
	border: 1px solid @collaxoWhiteAlpha50;
	border-radius: 4px;
	font-family: @vaud;
	font-size: @fontSize18;
	color: @collaxoWhite;
	
	&:focus {
		border-color: @collaxoWhite;
		opacity: 1;
		outline: none;
		::-webkit-input-placeholder { /* WebKit, Blink, Edge */
			color: @collaxoWhite;
			opacity: 1;
		}
		:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
			color: @collaxoWhite;
			opacity: 1;
		}
		::-moz-placeholder { /* Mozilla Firefox 19+ */
			color: @collaxoWhite;
			opacity: 1;
		}
		:-ms-input-placeholder { /* Internet Explorer 10-11 */
			color: @collaxoWhite;
			opacity: 1;
		}
	}
	
	&.error {
		border-color: red;
	}
}

input[type="radio"] {
	-webkit-appearance: none;
	appearance: none;
	-moz-appearance: radio;
	background-image: url('/site/images/radiobutton.svg');
	background-color: transparent;
	width: 25px;
	height: 25px;
	background-size: cover;
	outline: none;
	margin-bottom: 0;
	border: none;
	
	&:checked {
		background-image: url('/site/images/radiobutton_active.svg');	
	}
}

label {
	opacity: 0.7;
	vertical-align: top;
	
	&.error {
		display: none!important;
	}
}

.formlabel-contactoptions {
	width: 100%;
	display: inline-block;
	padding: 0 0 10px 0;
	border-bottom: 1px solid @collaxoWhite;
	opacity: 0.7;
	font-family: @vaud;
	color: @collaxoWhite;
	font-size: @fontSize18;
}

.formsubmit {
	input {
		-webkit-appearance: none;
		appearance: none;
		-moz-appearance: none;
		margin-top: 20px;
		border: transparent;
		opacity: 1;
		padding-left: 0;
		width: 183px;
		border: 2px solid @collaxoTurquoise;
		color: @collaxoTurquoise;
		background-color: transparent;
		cursor: pointer;
		
		&:hover {
			background-color: @collaxoTurquoise;
			color: @collaxoWhite;
		}	
	}
}


.submit {
	&:active {
		-webkit-appearance: none;
		appearance: none;
		-moz-appearance: none;	
	}
}

.page__contact,
.page__impressum {
	display: none;
	position: relative;
	width: 100%;
}

.impressum-wrapper {
	.section-article {
		padding-top: 80px;
		padding-bottom: 80px;
		
		h3,
		p,
		a {
			color: @collaxoWhite;
		}
		
		p {
			font-size: @fontSize18;
		}
	}
}

.loader {
	width: 60px;
	display: inline-block;
	position: absolute;
	right: 0;
	bottom: 20px;
	opacity: 0;
}

/*********************************************************************
	IE FIX
*********************************************************************/
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.xform {
		 .radios {
			 .radio {
				  input[type="radio"]:checked,
				  input[type="radio"] {
					width: 20px;
					height: 20px;
					padding: 0;
					border-radius: 10px;
					background-image: none;
					background-color: white;
					box-sizing: content-box;
				}
			 }
		 }
	}
}